expense_count = 0;
expenses = [];
var field_arr = ['expense_type', 'business_purpose', 'image', 'receipt_total']

$(document).on('click', '.expense-docs', function() {
  $('#expense_modal').addClass('is-active')
});

$(document).on('change', '#expense_expense_type', function() {
  $('#label-change').text($('option:selected',this).text());
});

$(document).on('click', '.view_receipt', function() {
  $('#view_receipts').addClass('is-active')
});


$(document).on('change', '.expesne-image-upload', function() {
  file = this.files[0];
  image = window.URL.createObjectURL(file);
  expense_type = $('#expense_expense_type').val();
  if (expense_type === '') {
    $('.expense-type-alert').removeClass('is-hidden');
  }else{
    expenses.push({image: file, expense_type: expense_type, index: expense_count})
    $('.expense-list').append(' <div class="columns mb-0"> <div class="column pb-0">' +expense_type+ '</div></div> <div class="columns mb-0"> <div class="column is-2"><img src='+image+' class="expense-img"></div> <div class="column is-5 pl-0"><div class="field"><div class="control"><input type="text" class="receipt-total input ml-2" data-count="'+expense_count+'" placeholder="Receipt Total"></div></div></div></div>');
    expense_count ++;
  }
});

$(document).on('blur', '.receipt-total', function() {
  index = parseInt(this.dataset.count)

  obj = expenses.find((e, i) => {
    if (e.index === index) {
      e.receipt_total = this.value
      expenses[i] = e;
      return true; // stop searching
    }
  });
});

$(document).on('blur', '#expense_business_purpose', function() {
  value = this.value
  $.each(expenses, function(i, v) {
    v.business_purpose = value;
    expenses[i] = v;
  });
});

$(document).on('click', '.expense-alert-delete', function() {
  $('.expense-alert').addClass('is-hidden');
});

$(document).on('click', '.expense-submit-btn', function(e) {
  $(".expense-submit-btn").prop('disabled', true);
  e.preventDefault();

  if(validate()) {
    submitForm($('#new_expense').prop("action"));
  } else {
    $('.expense-alert').removeClass('is-hidden');
    $(".expense-submit-btn").removeAttr('disabled');
  }
});

function validate() {
  flag = true;
  if(expenses.length == 0) {
    flag = false;
  }
  $.each(expenses, function(i, v) {
    $.each(field_arr, function(i, field) {
      if(!(field in v)) {
        flag = false
      }
    })
  });
  return flag;
}

function submitForm(url) {
  var fd = new FormData();
  $.each(expenses, function(i, e) {
    $.each(e, function(k, v){
      fd.append(k, v);
    })
    $.ajax({
      url: url,
      data: fd,
      type: 'POST',
      processData: false,
      contentType: false
      //success: function(data){
      //  alert(data);
      //}
    });
  });
}

$(document).on('submit', '#new_expense', function(e) {
  $('#waiting').show();
  $(this).children('input[type=submit]').prop('disabled', true);
});

$(document).on('click', '.expense-alert-delete', function() {
  $('.expense-type-alert').addClass('is-hidden');
});